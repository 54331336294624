<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-text>
          <b-row>
            <b-col
              cols="12"
            >
              <h6 class="mb-1">
                Oluştur
              </h6>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group label="Başlık">
                <b-form-input
                  v-model="formData.title"
                  placeholder="Başlık"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
      <b-button
        variant="primary"
        @click="submitForm"
      >
        Kaydet
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BButton, BFormGroup,
} from 'bootstrap-vue'

export default {
  name: 'Add',
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        title: null,
      },
    }
  },
  computed: {
    saveStatus() {
      return this.$store.getters['marketingAdTypes/getMarketing_ad_typeSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          id_com_brand: null,
          id_com_location: null,
          sdate: null,
          edate: null,
          count: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('marketingAdTypes/marketing_ad_typeSave', this.formData)
    },
  },
}
</script>
